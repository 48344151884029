<template>
  <div class="dashboard">
    <el-card v-if="!user.isTutorialSkipped" class="dashboard__tutorial" shadow="none">
      <div class="dashboard__tutorial__header">
        <h6>{{ $t('tutorial.title') }}</h6>
        <el-button type="text" @click="handleSkipTutorial">{{ $t('tutorial.skip') }}</el-button>
      </div>
      <p>{{ $t('tutorial.description') }}</p>
      <div class="dashboard__tutorial__steps">
        <el-card
          v-for="step in $t('steps')"
          :key="step"
          class="dashboard__tutorial__step"
          shadow="none"
        >
          <icon class="step__icon-check" height="32" width="32" name="check-circle" />
          <p>{{ step }}</p>
        </el-card>
      </div>
    </el-card>
    <div class="dashboard__panel">
      <div class="dashboard__panel__container">
        <el-card class="dashboard__stats" shadow="none">
          <div class="dashboard__stats__header">
            <h6>{{ $t(`tables.${activeTable}`) }}</h6>
            <el-select v-model="tablePeriod" size="small">
              <icon slot="prefix" height="20" width="20" name="date" />
              <el-option
                v-for="period in tablePeriods"
                :key="period.name"
                :label="$t(`date.${period.name}`)"
                :value="period.name"
              />
            </el-select>
          </div>
          <div class="dashboard__stats__content">
            <bar-chart
              :chart-data="{
                labels: tableLabels,
                datasets: tableDatasets,
              }"
            />
          </div>
        </el-card>
      </div>
      <div class="dashboard__panel__container">
        <el-card
          v-for="table in tables"
          :key="table.name"
          :class="['dashboard__card', { 'dashboard__card--active': activeTable === table.name }]"
          shadow="none"
          @click.native="activeTable = table.name"
        >
          <icon class="dashboard__card__icon" height="32" width="32" :name="table.icon" />
          <div class="dashboard__card__indicator">
            <span>{{ $t(`tables.${table.name}`) }}</span>
            <b>{{ table.count }}</b>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import BarChart from '@/components/charts/BarChart'

export default {
  name: 'Dashboard',

  components: {
    BarChart,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('title'),
    }
  },

  data() {
    const month = new Date().getMonth()
    let daysInMonth = [4, 6, 9, 11].includes(month) ? 30 : 31
    if (month === 2) daysInMonth = 28

    return {
      activeTable: 'sources',
      tables: [
        {
          name: 'sources',
          icon: 'import',
          count: Math.floor(Math.random() * 100) + 10,
        },
        {
          name: 'profiles',
          icon: 'user',
          count: Math.floor(Math.random() * 100) + 10,
        },
        {
          name: 'purchases',
          icon: 'filter',
          count: Math.floor(Math.random() * 100) + 10,
        },
        {
          name: 'segments',
          icon: 'store',
          count: Math.floor(Math.random() * 100) + 10,
        },
        {
          name: 'exports',
          icon: 'export',
          count: Math.floor(Math.random() * 100) + 10,
        },
      ],
      tablePeriod: 'year',
      tablePeriods: [
        { name: 'year', labels: this.$i18n.t('date.months') },
        { name: 'month', labels: [...Array(daysInMonth + 1).keys()].slice(1) },
        { name: 'week', labels: this.$i18n.t('date.daysOfWeek') },
        { name: 'day', labels: [...Array(25).keys()].slice(1) },
      ],
      tableDatasets: [],
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('data', ['activeCloud']),
    tableLabels() {
      return this.tablePeriods.find(period => this.tablePeriod === period.name).labels
    },
  },

  watch: {
    activeTable() {
      this.setTableDatasets()
    },
  },

  beforeMount() {
    this.setTableDatasets()
  },

  methods: {
    ...mapActions('user', ['editUser']),
    setTableDatasets() {
      this.tableDatasets = [
        {
          label: this.$i18n.t('data.statuses.active'),
          backgroundColor: '#9DEAF1',
          data: [...new Array(31)].map(() => Math.round(Math.random() * 30)),
        },
        {
          label: this.$i18n.t('data.statuses.pending'),
          backgroundColor: '#4652AD',
          data: [...new Array(31)].map(() => Math.round(Math.random() * 20)),
        },
        {
          label: this.$i18n.t('data.statuses.stalled'),
          backgroundColor: '#E4E5F2',
          data: [...new Array(31)].map(() => Math.round(Math.random() * 10)),
        },
      ]
    },
    async handleSkipTutorial() {
      await this.editUser({ isTutorialSkipped: true })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Dashboard",
    "tutorial": {
      "title": "Step by step guide",
      "description": "Check out step-by-step tutorial and cover all points to get ready to meet your product tasks!",
      "skip": "Skip tutorial"
    },
    "steps": [
      "Add Data Source in Data Management",
      "Create new workspace",
      "Add and edit workspace items",
      "Export workspace items to destination"
    ],
    "tables": {
      "sources": "Sources",
      "profiles": "Profiles",
      "purchases": "Purchases",
      "segments": "Segments",
      "exports": "Exports"
    }
  },
  "ru": {
    "title": "Дашборд",
    "tutorial": {
      "title": "Пошаговое руководство",
      "description": "Выполните все пункты руководства и настройте свое рабочее пространство для решения задач!",
      "skip": "Пропустить"
    },
    "steps": [
      "Добавьте источник данных в Управлении данными ",
      "Создайте новое рабочее пространство",
      "Добавьте и настройте виджеты в рабочем пространстве",
      "Настройте экспорт элементов рабочего пространства"
    ],
    "tables": {
      "sources": "Источники",
      "profiles": "Профили",
      "purchases": "Закупки",
      "segments": "Сегменты",
      "exports": "Экспорты"
    }
  }
}
</i18n>

<style lang="scss">
.dashboard {
  padding: $spacing-l;
}
.dashboard__panel {
  display: flex;
}
.dashboard__panel__container {
  &:first-of-type {
    flex: 2;
    margin-right: $spacing-l;
  }
}
.dashboard__card {
  margin-bottom: $spacing-l;
  width: 100%;
  min-width: 200px;
  cursor: pointer;
  border: 2px solid $color-layout-light-02;
  transition: all 0.3s ease-in-out;
  &:last-of-type {
    margin-bottom: 0;
  }
  &--active {
    box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.6), 0px 10px 20px rgba(0, 0, 0, 0.02);
    transition: all 0.3s ease-in-out;
    border: 2px solid #acb3da;
  }
  .el-card__body {
    display: flex;
  }
}
.dashboard__card__icon {
  color: $color-primary;
  margin-right: $spacing-xl;
}
.dashboard__card__indicator {
  display: flex;
  flex-direction: column;
  margin-right: $spacing-xl;
  &:last-of-type {
    margin-right: 0;
  }
  & > span {
    margin-bottom: $spacing-2xs;
  }
}
.dashboard__stats {
  flex: 2;
  height: 100%;
}
.dashboard__tutorial {
  position: relative;
  background-color: #d5d9f0;
  border: 0;
  margin-bottom: $spacing-l;
  h6 {
    font-weight: 600;
  }
  p {
    margin-bottom: $spacing-m;
  }
  .el-card_body {
    padding: $spacing-xs;
  }
  &::after {
    position: absolute;
    display: bottom;
    content: '';
    bottom: 0;
    right: 30px;
    width: 290px;
    height: 190px;
    background: url(../assets/images/tutorial.svg) no-repeat no-repeat;
    background-size: contain;
  }
}
.dashboard__tutorial__steps {
  display: flex;
}
.dashboard__tutorial__step {
  max-width: 160px;
  border: 0;
  border-radius: 8px;
  margin-right: $spacing-m;
  .el-card__body {
    padding: $spacing-s;
    text-align: center;
    svg {
      margin-bottom: $spacing-xs;
    }
    p {
      margin-bottom: $spacing-2xs;
    }
  }
}
.step__icon-check .primary {
  color: $color-success;
}
.step__icon-check .secondary {
  color: white;
}
.dashboard__tutorial__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-button {
    z-index: 10;
  }
}
.dashboard__stats__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__tutorial__icons {
  margin-right: $spacing-s;
}
.tutorial__icons--warning {
  color: $color-warning;
}
.tutorial__icons--success {
  color: $color-success;
}
</style>
